import { assertBankAccountFromStore } from "@/store/modules/onboarding/conditionalApiFields/conditionHelpers";
import { CountryBasedApiField, OnboardingSectionData } from "@/store/modules/onboarding/onboardingTypes";
import { accountNumber, accountType, bankCode, iban, sortCode } from "@/app/validators/countrySpecificValidationSpec";
import { BankTransferType, Country } from "@/app/utils/common/constants";

export default {
  apiFields: {
    name: {
      apiPath: "payment_instrument.bank_account.name",
      value: null,
      formattedValue: undefined,
      valid: false,
      label: {
        default: "bankAccountForPayouts.accountHolderName.label",
      },
      instruction: {
        default: "bankAccountForPayouts.accountHolderName.instruction"
      },
      shouldShowInView: (rootGetters) =>
        assertBankAccountFromStore(rootGetters, BankTransferType.BACS_DEBIT, BankTransferType.SEPA_DEBIT, BankTransferType.ACH_DEBIT),
    },
    accountNumber: {
      apiPath: "payment_instrument.bank_account.account_number",
      value: null,
      formattedValue: undefined,
      valid: false,
      label: {
        default: "bankAccountForPayouts.accountNumber.label",
      },
      instruction: {
        default: "bankAccountForPayouts.accountNumber.instruction",
      },
      shouldShowInView: (rootGetters) =>
        assertBankAccountFromStore(rootGetters, BankTransferType.ACH_DEBIT, BankTransferType.BACS_DEBIT),
      validationSpec: accountNumber
    },
    iban: {
      apiPath: "payment_instrument.bank_account.iban",
      value: null,
      formattedValue: undefined,
      valid: false,
      label: {
        default: "bankAccountForPayouts.iban.label",
      },
      instruction: {
        default: "bankAccountForPayouts.iban.instruction",
      },
      shouldShowInView: (rootGetters) =>
        assertBankAccountFromStore(rootGetters, BankTransferType.SEPA_DEBIT),
      validationSpec: iban
    },
    accountType: {
      apiPath: "payment_instrument.bank_account.account_type",
      value: null,
      formattedValue: undefined,
      valid: false,
      label: {
        default: "bankAccountForPayouts.accountType.label",
      },
      instruction: {
        default: "bankAccountForPayouts.accountType.instruction"
      },
      shouldShowInView: (rootGetters) =>
        assertBankAccountFromStore(rootGetters, BankTransferType.ACH_DEBIT),
      validationSpec: accountType
    },
    bankCode: {
      apiPath: "payment_instrument.bank_account.bank_code",
      value: null,
      formattedValue: undefined,
      valid: false,
      label: {
        default: "bankAccountForPayouts.bankCode.label",
      },
      instruction: {
        default: "bankAccountForPayouts.bankCode.instruction",
      },
      shouldShowInView: (rootGetters) =>
        assertBankAccountFromStore(rootGetters, BankTransferType.ACH_DEBIT),
      validationSpec: bankCode
    },
    sortCode: {
      apiPath: "payment_instrument.bank_account.sort_code",
      value: null,
      formattedValue: undefined,
      valid: false,
      label: {
        default: "bankAccountForPayouts.sortCode.label",
      },
      instruction: {
        default: "bankAccountForPayouts.sortCode.instruction",
      },
      shouldShowInView: (rootGetters) =>
        assertBankAccountFromStore(rootGetters, BankTransferType.BACS_DEBIT),
      validationSpec: sortCode
    },
    reenterAccountNumber: {
      apiPath: "payment_instrument.bank_account.account_number",
      value: null,
      valid: false,
      label: {
        default: "bankAccountForPayouts.reenterAccountNumber.accountNumber.label",
        [Country.DEU]: "bankAccountForPayouts.reenterAccountNumber.iban.label",
        [Country.FRA]: "bankAccountForPayouts.reenterAccountNumber.iban.label",
        [Country.ESP]: "bankAccountForPayouts.reenterAccountNumber.iban.label",
        [Country.NLD]: "bankAccountForPayouts.reenterAccountNumber.iban.label",
        [Country.BEL]: "bankAccountForPayouts.reenterAccountNumber.iban.label",
        [Country.ITA]: "bankAccountForPayouts.reenterAccountNumber.iban.label",
      },
      instruction: {
        default: "bankAccountForPayouts.reenterAccountNumber.accountNumber.label",
        [Country.DEU]: "bankAccountForPayouts.reenterAccountNumber.iban.label",
        [Country.FRA]: "bankAccountForPayouts.reenterAccountNumber.iban.label",
        [Country.ESP]: "bankAccountForPayouts.reenterAccountNumber.iban.label",
        [Country.NLD]: "bankAccountForPayouts.reenterAccountNumber.iban.label",
        [Country.BEL]: "bankAccountForPayouts.reenterAccountNumber.iban.label",
        [Country.ITA]: "bankAccountForPayouts.reenterAccountNumber.iban.label",
      },
      shouldShowInView: (rootGetters) =>
        assertBankAccountFromStore(rootGetters, BankTransferType.ACH_DEBIT, BankTransferType.BACS_DEBIT, BankTransferType.SEPA_DEBIT),
      validationSpec: accountNumber
    },
  },
  getters: {
    shouldShowApiField: (state: OnboardingSectionData, getters, rootState, rootGetters) => (apiFieldName) => {
      return (state.data[apiFieldName] as CountryBasedApiField).shouldShowInView(rootGetters)
    },
    shouldAllowCopyFromApiResponseToApiField: (state, getters, rootState) => (flatApiResponse, apiFieldName) => {
      return (state.data[apiFieldName] as CountryBasedApiField).shouldAllowCopyFromApiResponse(rootState, flatApiResponse)
    },
  },
  actions: {
  },
  mutations: {
  },
}