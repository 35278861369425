/* eslint-disable  @typescript-eslint/no-non-null-assertion */

export const stateList = {
  USA: {
    Alabama: 'AL',
    Alaska: 'AK',
    Arizona: 'AZ',
    Arkansas: 'AR',
    California: 'CA',
    Colorado: 'CO',
    Connecticut: 'CT',
    Delaware: 'DE',
    'District of Columbia (DC)': 'DC',
    Florida: 'FL',
    Georgia: 'GA',
    Hawaii: 'HI',
    Idaho: 'ID',
    Illinois: 'IL',
    Indiana: 'IN',
    Iowa: 'IA',
    Kansas: 'KS',
    Kentucky: 'KY',
    Louisiana: 'LA',
    Maine: 'ME',
    Maryland: 'MD',
    Massachusetts: 'MA',
    Michigan: 'MI',
    Minnesota: 'MN',
    Mississippi: 'MS',
    Missouri: 'MO',
    Montana: 'MT',
    Nebraska: 'NE',
    Nevada: 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    Ohio: 'OH',
    Oklahoma: 'OK',
    Oregon: 'OR',
    Pennsylvania: 'PA',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    Tennessee: 'TN',
    Texas: 'TX',
    Utah: 'UT',
    Vermont: 'VT',
    Virginia: 'VA',
    Washington: 'WA',
    'West Virginia': 'WV',
    Wisconsin: 'WI',
    Wyoming: 'WY'
  },
  CAN: {
    Alberta: 'AB',
    'British Columbia': 'BC',
    Manitoba: 'MB',
    'New Brunswick': 'NB',
    'Newfoundland And Labrador': 'NL',
    'Northwest Territories': 'NT',
    'Nova Scotia': 'NS',
    Nunavut: 'NU',
    Ontario: 'ON',
    'Prince Edward Island': 'PE',
    Quebec: 'QC',
    Saskatchewan: 'SK',
    Yukon: 'YT'
  },
  IND: {
    'Andaman and Nicobar Islands': 'AN',
    'Andhra Pradesh': 'AP',
    'Arunachal Pradesh': 'AR',
    Assam: 'AS',
    Bihar: 'BR',
    Chandigarh: 'CH',
    Chhattisgarh: 'CT',
    'Dadra and Nagar Haveli': 'DN',
    'Daman and Diu': 'DD',
    'Delhi': 'DL',
    Goa: 'GA',
    Gujarat: 'GJ',
    Haryana: 'HR',
    'Himachal Pradesh': 'HP',
    'Jammu and Kashmir': 'JK',
    Jharkhand: 'JH',
    Karnataka: 'KA',
    Kerala: 'KL',
    Lakshadweep: 'LD',
    'Madhya Pradesh': 'MP',
    Maharashtra: 'MH',
    Manipur: 'MN',
    Meghalaya: 'ML',
    Mizoram: 'MZ',
    Nagaland: 'NL',
    Odisha: 'OD',
    Pondicherry: 'PY',
    Punjab: 'PB',
    Rajasthan: 'RJ',
    Sikkim: 'SK',
    'Tamil Nadu': 'TN',
    Telangana: 'TG',
    Tripura: 'TR',
    'Uttar Pradesh': 'UP',
    Uttarakhand: 'UT',
    'West Bengal': 'WB'
  },
  DEU: {
    'Baden-Württemberg': 'BW',
    Bayern: 'BY',
    Berlin: 'BE',
    Brandenburg: 'BB',
    Bremen: 'HB',
    Hamburg: 'HH',
    Hessen: 'HE',
    'Mecklenburg-Vorpommern': 'MV',
    Niedersachsen: 'NI',
    'Nordrhein-Westfalen': 'NW',
    'Rheinland-Pfalz': 'RP',
    Saarland: 'SL',
    Sachsen: 'SN',
    'Sachsen-Anhalt': 'ST',
    'Schleswig-Holstein': 'SH',
    Thüringen: 'TH'
  },
  GBR: {
    'England': 'ENG',
    'Scotland': 'SCT',
    'Wales': 'WLS',
    'Northern Ireland': 'NIR'
  },
  FRA: {
    'Auvergne-Rhône-Alpes': 'ARA',
    Bretagne: 'BRE',
    'Bourgogne-Franche-Comté': 'BFC',
    'Centre-Val de Loire': 'CVL',
    'Corse': 'COR',
    'Grand Est': 'GES',
    'Hauts-de-France': 'HDF',
    'Île-de-France': 'IDF',
    Normandie: 'NOR',
    'Nouvelle-Aquitaine': 'NAQ',
    Occitanie: 'OCC',
    'Pays de la Loire': 'PDL',
    'Provence-Alpes-Côte dAzur': 'PAC'
  },
  ESP: {
    'Andalucía': 'AN',
    'Aragón': 'AR',
    Asturias: 'AS',
    'Balearic Islands': 'IB',
    'Basque Country': 'PV',
    'Canary Islands': 'CN',
    Cantabria: 'CB',
    'Castilla-La Mancha': 'CM',
    'Castilla y León': 'CL',
    Catalonia: 'CT',
    Extremadura: 'EX',
    Galicia: 'GA',
    'La Rioja': 'RI',
    Madrid: 'MD',
    Murcia: 'MU',
    Navarra: 'NC',
    Valencia: 'VC'
  },
  NLD: {
    Drenthe: 'DR',
    Flevoland: 'FL',
    Friesland: 'FR',
    Gelderland: 'GE',
    Groningen: 'GR',
    Limburg: 'LI',
    'North Brabant': 'NB',
    'North Holland': 'NH',
    Overijssel: 'OV',
    'South Holland': 'ZH',
    Utrecht: 'UT',
    Zeeland: 'ZE'
  },
  ITA: {
    Abruzzo: 'AB',
    Basilicata: 'BA',
    Calabria: 'CL',
    Campania: 'CM',
    'Emilia-Romagna': 'ER',
    'Friuli Venezia Giulia': 'FV',
    Lazio: 'LA',
    Liguria: 'LI',
    Lombardia: 'LO',
    Marche: 'MA',
    Molise: 'MO',
    Piemonte: 'PI',
    Puglia: 'PU',
    Sardegna: 'SA',
    Sicilia: 'SI',
    Toscana: 'TO',
    Trentino: 'TR',
    Umbria: 'UM',
    'Valle d\'Aosta': 'VA',
    Veneto: 'VE'
  },
  BEL: {
    Antwerp: 'AN',
    'Brussels-Capital': 'BR',
    'East Flanders': 'EF',
    'Flemish Brabant': 'FB',
    Hainaut: 'HA',
    'Liège': 'LG',
    Limburg: 'LI',
    Luxembourg: 'LX',
    Namur: 'NA',
    'Walloon Brabant': 'WB',
    'West Flanders': 'WF'
  }

  // IMPORTANT: Need to cross check the state codes with authentic sources it is currently taken from Chatgpt

};

export const stateName = (stateCode: string, countryCode: string): string => {
  return Object.keys(stateList[countryCode]).find(key => stateList[countryCode][key] === stateCode)!;
};

export default stateList;
