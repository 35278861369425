import {CountryCode} from "@/api/paymentApi";

export const CountryCodeList = [
        {
            "displayText": "Aruba",
            "value": CountryCode.ABW,
            "dialCode": "+297"
        },
        {
            "displayText": "Afghanistan",
            "value": CountryCode.AFG,
            "dialCode": "+93"
        },
        {
            "displayText": "Angola",
            "value": CountryCode.AGO,
            "dialCode": "+244"
        },
        {
            "displayText": "Anguilla",
            "value": CountryCode.AIA,
            "dialCode": "+1264"
        },
        {
            "displayText": "Aland Islands",
            "value": CountryCode.ALA,
            "dialCode": "+358"
        },
        {
            "displayText": "Albania",
            "value": CountryCode.ALB,
            "dialCode": "+355"
        },
        {
            "displayText": "Andorra",
            "value": CountryCode.AND,
            "dialCode": "+376"
        },
        {
            "displayText": "Netherlands Antilles",
            "value": CountryCode.ANT,
            "dialCode": "+599"
        },
        {
            "displayText": "United Arab Emirates",
            "value": CountryCode.ARE,
            "dialCode": "+971"
        },
        {
            "displayText": "Argentina",
            "value": CountryCode.ARG,
            "dialCode": "+54"
        },
        {
            "displayText": "Armenia",
            "value": CountryCode.ARM,
            "dialCode": "+374"
        },
        {
            "displayText": "American Samoa",
            "value": CountryCode.ASM,
            "dialCode": "+1684"
        },
        {
            "displayText": "Antarctica",
            "value": CountryCode.ATA,
            "dialCode": "+672"
        },
        {
            "displayText": "French Southern Territories",
            "value": CountryCode.ATF,
            "dialCode": "+262"
        },
        {
            "displayText": "Antigua And Barbuda",
            "value": CountryCode.ATG,
            "dialCode": "+1268"
        },
        {
            "displayText": "Australia",
            "value": CountryCode.AUS,
            "dialCode": "+61"
        },
        {
            "displayText": "Austria",
            "value": CountryCode.AUT,
            "dialCode": "+43"
        },
        {
            "displayText": "Azerbaijan",
            "value": CountryCode.AZE,
            "dialCode": "+994"
        },
        {
            "displayText": "Burundi",
            "value": CountryCode.BDI,
            "dialCode": "+257"
        },
        {
            "displayText": "Belgium",
            "value": CountryCode.BEL,
            "dialCode": "+32",
            "enableCbPayments": true
        },
        {
            "displayText": "Benin",
            "value": CountryCode.BEN,
            "dialCode": "+229"
        },
        {
            "displayText": "Sint Eustatius And Saba Bonaire",
            "value": CountryCode.BES,
            "dialCode": "+599"
        },
        {
            "displayText": "Burkina Faso",
            "value": CountryCode.BFA,
            "dialCode": "+226"
        },
        {
            "displayText": "Bangladesh",
            "value": CountryCode.BGD,
            "dialCode": "+880"
        },
        {
            "displayText": "Bulgaria",
            "value": CountryCode.BGR,
            "dialCode": "+359"
        },
        {
            "displayText": "Bahrain",
            "value": CountryCode.BHR,
            "dialCode": "+973"
        },
        {
            "displayText": "Bahamas",
            "value": CountryCode.BHS,
            "dialCode": "+1242"
        },
        {
            "displayText": "Bosnia And Herzegovina",
            "value": CountryCode.BIH,
            "dialCode": "+387"
        },
        {
            "displayText": "Saint Barthélemy",
            "value": CountryCode.BLM,
            "dialCode": "+590"
        },
        {
            "displayText": "Belarus",
            "value": CountryCode.BLR,
            "dialCode": "+375"
        },
        {
            "displayText": "Belize",
            "value": CountryCode.BLZ,
            "dialCode": "+501"
        },
        {
            "displayText": "Bermuda",
            "value": CountryCode.BMU,
            "dialCode": "+1441"
        },
        {
            "displayText": "Bolivia",
            "value": CountryCode.BOL,
            "dialCode": "+591"
        },
        {
            "displayText": "Brazil",
            "value": CountryCode.BRA,
            "dialCode": "+55"
        },
        {
            "displayText": "Barbados",
            "value": CountryCode.BRB,
            "dialCode": "+1246"
        },
        {
            "displayText": "Brunei Darussalam",
            "value": CountryCode.BRN,
            "dialCode": "+673"
        },
        {
            "displayText": "Bhutan",
            "value": CountryCode.BTN,
            "dialCode": "+975"
        },
        {
            "displayText": "Bouvet Island",
            "value": CountryCode.BVT,
            "dialCode": "+47"
        },
        {
            "displayText": "Botswana",
            "value": CountryCode.BWA,
            "dialCode": "+267"
        },
        {
            "displayText": "Central African Republic",
            "value": CountryCode.CAF,
            "dialCode": "+236"
        },
        {
            "displayText": "Canada",
            "value": CountryCode.CAN,
            "dialCode": "+1"
        },
        {
            "displayText": "Cocos (Keeling) Islands",
            "value": CountryCode.CCK,
            "dialCode": "+61"
        },
        {
            "displayText": "Switzerland",
            "value": CountryCode.CHE,
            "dialCode": "+41"
        },
        {
            "displayText": "Chile",
            "value": CountryCode.CHL,
            "dialCode": "+56"
        },
        {
            "displayText": "China",
            "value": CountryCode.CHN,
            "dialCode": "+86"
        },
        {
            "displayText": "Côte D'ivoire",
            "value": CountryCode.CIV,
            "dialCode": "+225"
        },
        {
            "displayText": "Cameroon",
            "value": CountryCode.CMR,
            "dialCode": "+237"
        },
        {
            "displayText": "Congo (Democratic Republic)",
            "value": CountryCode.COD,
            "dialCode": "+243"
        },
        {
            "displayText": "Congo",
            "value": CountryCode.COG,
            "dialCode": "+242"
        },
        {
            "displayText": "Cook Islands",
            "value": CountryCode.COK,
            "dialCode": "+682"
        },
        {
            "displayText": "Colombia",
            "value": CountryCode.COL,
            "dialCode": "+57"
        },
        {
            "displayText": "Comoros",
            "value": CountryCode.COM,
            "dialCode": "+269"
        },
        {
            "displayText": "Cape Verde",
            "value": CountryCode.CPV,
            "dialCode": "+238"
        },
        {
            "displayText": "Costa Rica",
            "value": CountryCode.CRI,
            "dialCode": "+506"
        },
        {
            "displayText": "Cuba",
            "value": CountryCode.CUB,
            "dialCode": "+53"
        },
        {
            "displayText": "Curaçao",
            "value": CountryCode.CUW,
            "dialCode": "+599"
        },
        {
            "displayText": "Christmas Island",
            "value": CountryCode.CXR,
            "dialCode": "+61"
        },
        {
            "displayText": "Cayman Islands",
            "value": CountryCode.CYM,
            "dialCode": "+1345"
        },
        {
            "displayText": "Cyprus",
            "value": CountryCode.CYP,
            "dialCode": "+357"
        },
        {
            "displayText": "Czech Republic",
            "value": CountryCode.CZE,
            "dialCode": "+420"
        },
        {
            "displayText": "Germany",
            "value": CountryCode.DEU,
            "dialCode": "+49",
            "enableCbPayments": true
        },
        {
            "displayText": "Djibouti",
            "value": CountryCode.DJI,
            "dialCode": "+253"
        },
        {
            "displayText": "Dominica",
            "value": CountryCode.DMA,
            "dialCode": "+1767"
        },
        {
            "displayText": "Denmark",
            "value": CountryCode.DNK,
            "dialCode": "+45"
        },
        {
            "displayText": "Dominican Republic",
            "value": CountryCode.DOM,
            "dialCode": "+1"
        },
        {
            "displayText": "Algeria",
            "value": CountryCode.DZA,
            "dialCode": "+213"
        },
        {
            "displayText": "Spain - Ceuta and Melilla",
            "value": CountryCode.EAC,
            "dialCode": "+34"
        },
        {
            "displayText": "Ecuador",
            "value": CountryCode.ECU,
            "dialCode": "+593"
        },
        {
            "displayText": "Egypt",
            "value": CountryCode.EGY,
            "dialCode": "+20"
        },
        {
            "displayText": "Eritrea",
            "value": CountryCode.ERI,
            "dialCode": "+291"
        },
        {
            "displayText": "Western Sahara",
            "value": CountryCode.ESH,
            "dialCode": "+212"
        },
        {
            "displayText": "Spain",
            "value": CountryCode.ESP,
            "dialCode": "+34",
            "enableCbPayments": true
        },
        {
            "displayText": "Estonia",
            "value": CountryCode.EST,
            "dialCode": "+372"
        },
        {
            "displayText": "Ethiopia",
            "value": CountryCode.ETH,
            "dialCode": "+251"
        },
        {
            "displayText": "Finland",
            "value": CountryCode.FIN,
            "dialCode": "+358"
        },
        {
            "displayText": "Fiji",
            "value": CountryCode.FJI,
            "dialCode": "+679"
        },
        {
            "displayText": "Falkland Islands (Malvinas)",
            "value": CountryCode.FLK,
            "dialCode": "+500"
        },
        {
            "displayText": "France",
            "value": CountryCode.FRA,
            "dialCode": "+33",
            "enableCbPayments": true
        },
        {
            "displayText": "Faroe Islands",
            "value": CountryCode.FRO,
            "dialCode": "+298"
        },
        {
            "displayText": "Micronesia",
            "value": CountryCode.FSM,
            "dialCode": "+691"
        },
        {
            "displayText": "Gabon",
            "value": CountryCode.GAB,
            "dialCode": "+241"
        },
        {
            "displayText": "United Kingdom",
            "value": CountryCode.GBR,
            "dialCode": "+44",
            "enableCbPayments": true
        },
        {
            "displayText": "Georgia",
            "value": CountryCode.GEO,
            "dialCode": "+995"
        },
        {
            "displayText": "Guernsey",
            "value": CountryCode.GGY,
            "dialCode": "+44"
        },
        {
            "displayText": "Ghana",
            "value": CountryCode.GHA,
            "dialCode": "+233"
        },
        {
            "displayText": "Gibraltar",
            "value": CountryCode.GIB,
            "dialCode": "+350"
        },
        {
            "displayText": "Guinea",
            "value": CountryCode.GIN,
            "dialCode": "+224"
        },
        {
            "displayText": "Guadeloupe",
            "value": CountryCode.GLP,
            "dialCode": "+590"
        },
        {
            "displayText": "Gambia",
            "value": CountryCode.GMB,
            "dialCode": "+220"
        },
        {
            "displayText": "Guinea-bissau",
            "value": CountryCode.GNB,
            "dialCode": "+245"
        },
        {
            "displayText": "Equatorial Guinea",
            "value": CountryCode.GNQ,
            "dialCode": "+240"
        },
        {
            "displayText": "Greece",
            "value": CountryCode.GRC,
            "dialCode": "+30"
        },
        {
            "displayText": "Grenada",
            "value": CountryCode.GRD,
            "dialCode": "+1473"
        },
        {
            "displayText": "Greenland",
            "value": CountryCode.GRL,
            "dialCode": "+299"
        },
        {
            "displayText": "Guatemala",
            "value": CountryCode.GTM,
            "dialCode": "+502"
        },
        {
            "displayText": "French Guiana",
            "value": CountryCode.GUF,
            "dialCode": "+594"
        },
        {
            "displayText": "Guam",
            "value": CountryCode.GUM,
            "dialCode": "+1671"
        },
        {
            "displayText": "Guyana",
            "value": CountryCode.GUY,
            "dialCode": "+592"
        },
        {
            "displayText": "Hong Kong",
            "value": CountryCode.HKG,
            "dialCode": "+852"
        },
        {
            "displayText": "Heard Island And Mcdonald Islands",
            "value": CountryCode.HMD,
            "dialCode": "+672"
        },
        {
            "displayText": "Honduras",
            "value": CountryCode.HND,
            "dialCode": "+504"
        },
        {
            "displayText": "Croatia",
            "value": CountryCode.HRV,
            "dialCode": "+385"
        },
        {
            "displayText": "Haiti",
            "value": CountryCode.HTI,
            "dialCode": "+509"
        },
        {
            "displayText": "Hungary",
            "value": CountryCode.HUN,
            "dialCode": "+36"
        },
        {
            "displayText": "Spain - Canary Islands",
            "value": CountryCode.IC,
            "dialCode": "+34"
        },
        {
            "displayText": "Indonesia",
            "value": CountryCode.IDN,
            "dialCode": "+62"
        },
        {
            "displayText": "Isle Of Man",
            "value": CountryCode.IMN,
            "dialCode": "+44"
        },
        {
            "displayText": "India",
            "value": CountryCode.IND,
            "dialCode": "+91"
        },
        {
            "displayText": "British Indian Ocean Territory",
            "value": CountryCode.IOT,
            "dialCode": "+246"
        },
        {
            "displayText": "Ireland",
            "value": CountryCode.IRL,
            "dialCode": "+353"
        },
        {
            "displayText": "Iran",
            "value": CountryCode.IRN,
            "dialCode": "+98"
        },
        {
            "displayText": "Iraq",
            "value": CountryCode.IRQ,
            "dialCode": "+964"
        },
        {
            "displayText": "Iceland",
            "value": CountryCode.ISL,
            "dialCode": "+354"
        },
        {
            "displayText": "Israel",
            "value": CountryCode.ISR,
            "dialCode": "+972"
        },
        {
            "displayText": "Italy",
            "value": CountryCode.ITA,
            "dialCode": "+39",
            "enableCbPayments": true
        },
        {
            "displayText": "Jamaica",
            "value": CountryCode.JAM,
            "dialCode": "+1876"
        },
        {
            "displayText": "Jersey",
            "value": CountryCode.JEY,
            "dialCode": "+44"
        },
        {
            "displayText": "Jordan",
            "value": CountryCode.JOR,
            "dialCode": "+962"
        },
        {
            "displayText": "Japan",
            "value": CountryCode.JPN,
            "dialCode": "+81"
        },
        {
            "displayText": "Kazakhstan",
            "value": CountryCode.KAZ,
            "dialCode": "+7"
        },
        {
            "displayText": "Kenya",
            "value": CountryCode.KEN,
            "dialCode": "+254"
        },
        {
            "displayText": "Kyrgyzstan",
            "value": CountryCode.KGZ,
            "dialCode": "+996"
        },
        {
            "displayText": "Cambodia",
            "value": CountryCode.KHM,
            "dialCode": "+855"
        },
        {
            "displayText": "Kiribati",
            "value": CountryCode.KIR,
            "dialCode": "+686"
        },
        {
            "displayText": "Saint Kitts And Nevis",
            "value": CountryCode.KNA,
            "dialCode": "+1869"
        },
        {
            "displayText": "Korea (Republic)",
            "value": CountryCode.KOR,
            "dialCode": "+82"
        },
        {
            "displayText": "Kuwait",
            "value": CountryCode.KWT,
            "dialCode": "+965"
        },
        {
            "displayText": "Lao (People's Democratic Republic)",
            "value": CountryCode.LAO,
            "dialCode": "+856"
        },
        {
            "displayText": "Lebanon",
            "value": CountryCode.LBN,
            "dialCode": "+961"
        },
        {
            "displayText": "Liberia",
            "value": CountryCode.LBR,
            "dialCode": "+231"
        },
        {
            "displayText": "Libya",
            "value": CountryCode.LBY,
            "dialCode": "+218"
        },
        {
            "displayText": "Saint Lucia",
            "value": CountryCode.LCA,
            "dialCode": "+1758"
        },
        {
            "displayText": "Liechtenstein",
            "value": CountryCode.LIE,
            "dialCode": "+423"
        },
        {
            "displayText": "Sri Lanka",
            "value": CountryCode.LKA,
            "dialCode": "+94"
        },
        {
            "displayText": "Lesotho",
            "value": CountryCode.LSO,
            "dialCode": "+266"
        },
        {
            "displayText": "Lithuania",
            "value": CountryCode.LTU,
            "dialCode": "+370"
        },
        {
            "displayText": "Luxembourg",
            "value": CountryCode.LUX,
            "dialCode": "+352"
        },
        {
            "displayText": "Latvia",
            "value": CountryCode.LVA,
            "dialCode": "+371"
        },
        {
            "displayText": "Macao",
            "value": CountryCode.MAC,
            "dialCode": "+853"
        },
        {
            "displayText": "Saint Martin (French Part)",
            "value": CountryCode.MAF,
            "dialCode": "+590"
        },
        {
            "displayText": "Morocco",
            "value": CountryCode.MAR,
            "dialCode": "+212"
        },
        {
            "displayText": "Monaco",
            "value": CountryCode.MCO,
            "dialCode": "+377"
        },
        {
            "displayText": "Moldova",
            "value": CountryCode.MDA,
            "dialCode": "+373"
        },
        {
            "displayText": "Madagascar",
            "value": CountryCode.MDG,
            "dialCode": "+261"
        },
        {
            "displayText": "Maldives",
            "value": CountryCode.MDV,
            "dialCode": "+960"
        },
        {
            "displayText": "Mexico",
            "value": CountryCode.MEX,
            "dialCode": "+52"
        },
        {
            "displayText": "Marshall Islands",
            "value": CountryCode.MHL,
            "dialCode": "+692"
        },
        {
            "displayText": "Macedonia",
            "value": CountryCode.MKD,
            "dialCode": "+389"
        },
        {
            "displayText": "Mali",
            "value": CountryCode.MLI,
            "dialCode": "+223"
        },
        {
            "displayText": "Malta",
            "value": CountryCode.MLT,
            "dialCode": "+356"
        },
        {
            "displayText": "Myanmar",
            "value": CountryCode.MMR,
            "dialCode": "+95"
        },
        {
            "displayText": "Montenegro",
            "value": CountryCode.MNE,
            "dialCode": "+382"
        },
        {
            "displayText": "Mongolia",
            "value": CountryCode.MNG,
            "dialCode": "+976"
        },
        {
            "displayText": "Northern Mariana Islands",
            "value": CountryCode.MNP,
            "dialCode": "+1670"
        },
        {
            "displayText": "Mozambique",
            "value": CountryCode.MOZ,
            "dialCode": "+258"
        },
        {
            "displayText": "Mauritania",
            "value": CountryCode.MRT,
            "dialCode": "+222"
        },
        {
            "displayText": "Montserrat",
            "value": CountryCode.MSR,
            "dialCode": "+1664"
        },
        {
            "displayText": "Martinique",
            "value": CountryCode.MTQ,
            "dialCode": "+596"
        },
        {
            "displayText": "Mauritius",
            "value": CountryCode.MUS,
            "dialCode": "+230"
        },
        {
            "displayText": "Malawi",
            "value": CountryCode.MWI,
            "dialCode": "+265"
        },
        {
            "displayText": "Malaysia",
            "value": CountryCode.MYS,
            "dialCode": "+60"
        },
        {
            "displayText": "Mayotte",
            "value": CountryCode.MYT,
            "dialCode": "+262"
        },
        {
            "displayText": "Namibia",
            "value": CountryCode.NAM,
            "dialCode": "+264"
        },
        {
            "displayText": "New Caledonia",
            "value": CountryCode.NCL,
            "dialCode": "+687"
        },
        {
            "displayText": "Niger",
            "value": CountryCode.NER,
            "dialCode": "+227"
        },
        {
            "displayText": "Norfolk Island",
            "value": CountryCode.NFK,
            "dialCode": "+672"
        },
        {
            "displayText": "Nigeria",
            "value": CountryCode.NGA,
            "dialCode": "+234"
        },
        {
            "displayText": "Nicaragua",
            "value": CountryCode.NIC,
            "dialCode": "+505"
        },
        {
            "displayText": "Niue",
            "value": CountryCode.NIU,
            "dialCode": "+683"
        },
        {
            "displayText": "United Kingdom (Northern Ireland)",
            "value": CountryCode.NIR,
            "dialCode": "+44"
        },
        {
            "displayText": "Netherlands",
            "value": CountryCode.NLD,
            "dialCode": "+31",
            "enableCbPayments": true
        },
        {
            "displayText": "Norway",
            "value": CountryCode.NOR,
            "dialCode": "+47"
        },
        {
            "displayText": "Nepal",
            "value": CountryCode.NPL,
            "dialCode": "+977"
        },
        {
            "displayText": "Nauru",
            "value": CountryCode.NRU,
            "dialCode": "+674"
        },
        {
            "displayText": "New Zealand",
            "value": CountryCode.NZL,
            "dialCode": "+64"
        },
        {
            "displayText": "Oman",
            "value": CountryCode.OMN,
            "dialCode": "+968"
        },
        {
            "displayText": "Pakistan",
            "value": CountryCode.PAK,
            "dialCode": "+92"
        },
        {
            "displayText": "Panama",
            "value": CountryCode.PAN,
            "dialCode": "+507"
        },
        {
            "displayText": "Pitcairn",
            "value": CountryCode.PCN,
            "dialCode": "+64"
        },
        {
            "displayText": "Peru",
            "value": CountryCode.PER,
            "dialCode": "+51"
        },
        {
            "displayText": "Philippines",
            "value": CountryCode.PHL,
            "dialCode": "+63"
        },
        {
            "displayText": "Palau",
            "value": CountryCode.PLW,
            "dialCode": "+680"
        },
        {
            "displayText": "Papua New Guinea",
            "value": CountryCode.PNG,
            "dialCode": "+675"
        },
        {
            "displayText": "Poland",
            "value": CountryCode.POL,
            "dialCode": "+48"
        },
        {
            "displayText": "Puerto Rico",
            "value": CountryCode.PRI,
            "dialCode": "+1"
        },
        {
            "displayText": "Korea (Democratic People's Republic)",
            "value": CountryCode.PRK,
            "dialCode": "+850"
        },
        {
            "displayText": "Portugal",
            "value": CountryCode.PRT,
            "dialCode": "+351"
        },
        {
            "displayText": "Paraguay",
            "value": CountryCode.PRY,
            "dialCode": "+595"
        },
        {
            "displayText": "Palestinian Territory (Occupied)",
            "value": CountryCode.PSE,
            "dialCode": "+970"
        },
        {
            "displayText": "French Polynesia",
            "value": CountryCode.PYF,
            "dialCode": "+689"
        },
        {
            "displayText": "Qatar",
            "value": CountryCode.QAT,
            "dialCode": "+974"
        },
        {
            "displayText": "Reunion",
            "value": CountryCode.REU,
            "dialCode": "+262"
        },
        {
            "displayText": "Romania",
            "value": CountryCode.ROU,
            "dialCode": "+40"
        },
        {
            "displayText": "Russian Federation",
            "value": CountryCode.RUS,
            "dialCode": "+7"
        },
        {
            "displayText": "Rwanda",
            "value": CountryCode.RWA,
            "dialCode": "+250"
        },
        {
            "displayText": "Saudi Arabia",
            "value": CountryCode.SAU,
            "dialCode": "+966"
        },
        {
            "displayText": "Sudan",
            "value": CountryCode.SDN,
            "dialCode": "+249"
        },
        {
            "displayText": "Senegal",
            "value": CountryCode.SEN,
            "dialCode": "+221"
        },
        {
            "displayText": "Singapore",
            "value": CountryCode.SGP,
            "dialCode": "+65"
        },
        {
            "displayText": "South Georgia And The South Sandwich Islands",
            "value": CountryCode.SGS,
            "dialCode": "+500"
        },
        {
            "displayText": "Saint Helena",
            "value": CountryCode.SHN,
            "dialCode": "+290"
        },
        {
            "displayText": "Svalbard And Jan Mayen",
            "value": CountryCode.SJM,
            "dialCode": "+47"
        },
        {
            "displayText": "Solomon Islands",
            "value": CountryCode.SLB,
            "dialCode": "+677"
        },
        {
            "displayText": "Sierra Leone",
            "value": CountryCode.SLE,
            "dialCode": "+232"
        },
        {
            "displayText": "El Salvador",
            "value": CountryCode.SLV,
            "dialCode": "+503"
        },
        {
            "displayText": "San Marino",
            "value": CountryCode.SMR,
            "dialCode": "+378"
        },
        {
            "displayText": "Somalia",
            "value": CountryCode.SOM,
            "dialCode": "+252"
        },
        {
            "displayText": "Saint Pierre And Miquelon",
            "value": CountryCode.SPM,
            "dialCode": "+508"
        },
        {
            "displayText": "Serbia",
            "value": CountryCode.SRB,
            "dialCode": "+381"
        },
        {
            "displayText": "South Sudan",
            "value": CountryCode.SSD,
            "dialCode": "+211"
        },
        {
            "displayText": "Sao Tome And Principe",
            "value": CountryCode.STP,
            "dialCode": "+239"
        },
        {
            "displayText": "Suriname",
            "value": CountryCode.SUR,
            "dialCode": "+597"
        },
        {
            "displayText": "Slovakia",
            "value": CountryCode.SVK,
            "dialCode": "+421"
        },
        {
            "displayText": "Slovenia",
            "value": CountryCode.SVN,
            "dialCode": "+386"
        },
        {
            "displayText": "Sweden",
            "value": CountryCode.SWE,
            "dialCode": "+46"
        },
        {
            "displayText": "Swaziland",
            "value": CountryCode.SWZ,
            "dialCode": "+268"
        },
        {
            "displayText": "Sint Maarten (Dutch Part)",
            "value": CountryCode.SXM,
            "dialCode": "+1721"
        },
        {
            "displayText": "Seychelles",
            "value": CountryCode.SYC,
            "dialCode": "+248"
        },
        {
            "displayText": "Syrian Arab Republic",
            "value": CountryCode.SYR,
            "dialCode": "+963"
        },
        {
            "displayText": "Turks And Caicos Islands",
            "value": CountryCode.TCA,
            "dialCode": "+1649"
        },
        {
            "displayText": "Chad",
            "value": CountryCode.TCD,
            "dialCode": "+235"
        },
        {
            "displayText": "Togo",
            "value": CountryCode.TGO,
            "dialCode": "+228"
        },
        {
            "displayText": "Thailand",
            "value": CountryCode.THA,
            "dialCode": "+66"
        },
        {
            "displayText": "Tajikistan",
            "value": CountryCode.TJK,
            "dialCode": "+992"
        },
        {
            "displayText": "Tokelau",
            "value": CountryCode.TKL,
            "dialCode": "+690"
        },
        {
            "displayText": "Turkmenistan",
            "value": CountryCode.TKM,
            "dialCode": "+993"
        },
        {
            "displayText": "Timor-leste",
            "value": CountryCode.TLS,
            "dialCode": "+670"
        },
        {
            "displayText": "Tonga",
            "value": CountryCode.TON,
            "dialCode": "+676"
        },
        {
            "displayText": "Trinidad And Tobago",
            "value": CountryCode.TTO,
            "dialCode": "+1868"
        },
        {
            "displayText": "Tunisia",
            "value": CountryCode.TUN,
            "dialCode": "+216"
        },
        {
            "displayText": "Turkey",
            "value": CountryCode.TUR,
            "dialCode": "+90"
        },
        {
            "displayText": "Tuvalu",
            "value": CountryCode.TUV,
            "dialCode": "+688"
        },
        {
            "displayText": "Taiwan",
            "value": CountryCode.TWN,
            "dialCode": "+886"
        },
        {
            "displayText": "Tanzania",
            "value": CountryCode.TZA,
            "dialCode": "+255"
        },
        {
            "displayText": "Uganda",
            "value": CountryCode.UGA,
            "dialCode": "+256"
        },
        {
            "displayText": "Ukraine",
            "value": CountryCode.UKR,
            "dialCode": "+380"
        },
        {
            "displayText": "United States Minor Outlying Islands",
            "value": CountryCode.UMI,
            "dialCode": "+1"
        },
        {
            "displayText": "Uruguay",
            "value": CountryCode.URY,
            "dialCode": "+598"
        },
        {
            "displayText": "United States",
            "value": CountryCode.USA,
            "dialCode": "+1",
            "enableCbPayments": true
        },
        {
            "displayText": "Uzbekistan",
            "value": CountryCode.UZB,
            "dialCode": "+998"
        },
        {
            "displayText": "Vatican City",
            "value": CountryCode.VAT,
            "dialCode": "+39"
        },
        {
            "displayText": "Saint Vincent And The Grenadines",
            "value": CountryCode.VCT,
            "dialCode": "+1784"
        },
        {
            "displayText": "Venezuela",
            "value": CountryCode.VEN,
            "dialCode": "+58"
        },
        {
            "displayText": "British Virgin Islands",
            "value": CountryCode.VGB,
            "dialCode": "+1284"
        },
        {
            "displayText": "U.S. Virgin Islands",
            "value": CountryCode.VIR,
            "dialCode": "+1340"
        },
        {
            "displayText": "Viet Nam",
            "value": CountryCode.VNM,
            "dialCode": "+84"
        },
        {
            "displayText": "Vanuatu",
            "value": CountryCode.VUT,
            "dialCode": "+678"
        },
        {
            "displayText": "Wallis And Futuna",
            "value": CountryCode.WLF,
            "dialCode": "+681"
        },
        {
            "displayText": "Samoa",
            "value": CountryCode.WSM,
            "dialCode": "+685"
        },
        {
            "displayText": "Kosovo",
            "value": CountryCode.XKX,
            "dialCode": "+383"
        },
        {
            "displayText": "Yemen",
            "value": CountryCode.YEM,
            "dialCode": "+967"
        },
        {
            "displayText": "South Africa",
            "value": CountryCode.ZAF,
            "dialCode": "+27"
        },
        {
            "displayText": "Zambia",
            "value": CountryCode.ZMB,
            "dialCode": "+260"
        },
        {
            "displayText": "Zimbabwe",
            "value": CountryCode.ZWE,
            "dialCode": "+263"
        }
    ]
;


export const CBPaymentsEnabledCountries = CountryCodeList.filter(x => x.enableCbPayments).sort((a,b) => a.displayText.localeCompare(b.displayText));

export const CountryCodeListSortedByName = CountryCodeList.sort((a,b) => a.displayText.localeCompare(b.displayText))