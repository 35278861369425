var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.transaction !== null)?_c('div',{staticClass:"transaction-status",class:{
    'success-status': ['SUCCESS', 'REFUNDED'].includes(_vm.transaction.state),
    'returned-status': _vm.transaction.state === 'RETURNED',
    'failure-status': ['FAILED', 'NOT_REFUNDED', 'INITIATION_AT_PAYFAC_FAILED'].includes(_vm.transaction.state),
    'canceled-status': _vm.transaction.state === 'CANCELED',
    'progress-status':
      !['SUCCESS', 'FAILED', 'CANCELED', 'NOT_REFUNDED', 'REFUNDED', 'RETURNED', 'INITIATION_AT_PAYFAC_FAILED'].includes(_vm.transaction.state)
  }},[_vm._v(" "+_vm._s(_vm.$t('transactions.status.' + _vm.transaction.state))+" ")]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }