import {CountryBasedApiField, OnboardingSectionData} from "@/store/modules/onboarding/onboardingTypes";
import {
    organisationRegistrationNumber,
    organisationTaxId,
    organisationVatNumber,
    soleProprietorRegistrationNumber,
    soleProprietorTaxId,
    soleProprietorVatNumber
} from "@/app/validators/countrySpecificValidationSpec";
import {
    assertBusinessCountryFromApiResponse,
    assertBusinessCountryFromStore,
    assertSoloProprietorshipFromApiResponse,
    assertSoloProprietorshipFromStore,
} from "@/store/modules/onboarding/conditionalApiFields/conditionHelpers";
import { Country } from "@/app/utils/common/constants";

const createLabelAndInstruction = (countries: Array<string>, field: string, textChangeforCountry: string | null, businessType: string | null) => {
    const result = {
        label: {},
        instruction: {},
    };

    countries.forEach((country) => {
        const countryLowerCase = country.toLowerCase();
        if (textChangeforCountry !== null && businessType !== null && country === textChangeforCountry) {
            result.label[country] = `businessStructure.${field}.${countryLowerCase}.${businessType}.label`;
            result.instruction[country] = `businessStructure.${field}.${countryLowerCase}.${businessType}.instruction`;
        } else {
            result.label[country] = `businessStructure.${field}.${countryLowerCase}.label`;
            result.instruction[country] = `businessStructure.${field}.${countryLowerCase}.instruction`;
        }
    });

    return result;
};

const createVisibilityAndCopyConditions = (countries, shouldShowSoloProprietorship) => {
    return {
        shouldShowInView: (rootGetters) =>
          assertBusinessCountryFromStore(rootGetters, ...countries) &&
          (shouldShowSoloProprietorship ? assertSoloProprietorshipFromStore(rootGetters) :
            !assertSoloProprietorshipFromStore(rootGetters)),

        shouldAllowCopyFromApiResponse: (rootState, flatApiResponse) =>
          assertBusinessCountryFromApiResponse(rootState, flatApiResponse, ...countries) &&
          (shouldShowSoloProprietorship ? assertSoloProprietorshipFromApiResponse(rootState, flatApiResponse) :
            !assertSoloProprietorshipFromApiResponse(rootState, flatApiResponse)),
    };
};




export default {
    apiFields: {
        organisationTaxId: {
            apiPath: "business_info.tax_info.tax_id",
            value: null,
            formattedValue: undefined,
            valid: false,
            ...createLabelAndInstruction([Country.USA, Country.DEU, Country.GBR, Country.NLD, Country.BEL],
              "taxId", null, null),
            ...createVisibilityAndCopyConditions([Country.USA, Country.DEU, Country.GBR, Country.NLD, Country.BEL], false),
            validationSpec: organisationTaxId
        },
        organisationRegistrationNumber: {
            apiPath: "business_info.tax_info.registration_number",
            value: null,
            formattedValue: undefined,
            valid: false,
            ...createLabelAndInstruction([Country.FRA, Country.DEU, Country.GBR, Country.ESP, Country.NLD, Country.ITA, Country.BEL],
              "registrationNumber", Country.NLD, "organisation"),
            ...createVisibilityAndCopyConditions([Country.FRA, Country.DEU, Country.GBR, Country.ESP, Country.NLD, Country.ITA, Country.BEL], false),
            validationSpec: organisationRegistrationNumber
        },
        organisationVatNumber: {
            apiPath: "business_info.tax_info.vat_number",
            value: null,
            formattedValue: undefined,
            valid: false,
            ...createLabelAndInstruction([Country.FRA, Country.DEU, Country.GBR, Country.ESP, Country.NLD, Country.ITA, Country.BEL],
              "vatNumber", null, null),
            ...createVisibilityAndCopyConditions([Country.FRA, Country.DEU, Country.GBR, Country.ESP, Country.NLD, Country.ITA, Country.BEL], false),
            validationSpec: organisationVatNumber
        },
        soleProprietorTaxId: {
            apiPath: "business_info.tax_info.tax_id",
            value: null,
            formattedValue: undefined,
            valid: false,
            ...createLabelAndInstruction([Country.USA], "taxId",
              null, null),
            ...createVisibilityAndCopyConditions([Country.USA], true),
            validationSpec: soleProprietorTaxId,
        },
        soleProprietorRegistrationNumber: {
            apiPath: "business_info.tax_info.registration_number",
            value: null,
            formattedValue: undefined,
            valid: false,
            ...createLabelAndInstruction([Country.FRA, Country.DEU, Country.GBR, Country.ESP, Country.NLD, Country.ITA, Country.BEL],
              "registrationNumber", Country.NLD, "soleProprietor"),
            ...createVisibilityAndCopyConditions([Country.FRA, Country.DEU, Country.GBR, Country.ESP, Country.NLD, Country.ITA, Country.BEL], true),
            validationSpec: soleProprietorRegistrationNumber,
        },
        soleProprietorVatNumber: {
            apiPath: "business_info.tax_info.vat_number",
            value: null,
            formattedValue: undefined,
            valid: false,
            ...createLabelAndInstruction([Country.GBR, Country.ITA], "vatNumber",
              null, null),
            ...createVisibilityAndCopyConditions([Country.GBR, Country.ITA], true),
            validationSpec: soleProprietorVatNumber,
        },
    },
    getters: {
        shouldShowApiField: (state: OnboardingSectionData, getters, rootState, rootGetters) => (apiFieldName) => {
            return (state.data[apiFieldName] as CountryBasedApiField)
              .shouldShowInView(rootGetters)
        },
        shouldAllowCopyFromApiResponseToApiField: (state, getters, rootState) => (flatApiResponse, apiFieldName) => {
            return (state.data[apiFieldName] as CountryBasedApiField)
              .shouldAllowCopyFromApiResponse(rootState, flatApiResponse)
        },
    },
    actions: {
    },
    mutations: {
    },
}