import { format, length, presence, regex, strictInt } from "@/app/validators/api/customValidations";
import { Country } from "@/app/utils/common/constants";

export const organisationRegistrationNumber = {
    [Country.FRA]: {
        length: length(14,14), "strictInt": strictInt()
    },
    [Country.DEU]: {
        format: format("^[A-Za-z]{2,3}\\d{1,6}[A-Za-z]{0,5}$")
    },
    [Country.GBR]: {
        length: length(8,8)
    },
    [Country.ESP]: {
        regex: regex("^[a-zA-Z][a-zA-Z0-9]{8}$", "start with 1 letter followed by 8 alphanumeric characters")
    },
    [Country.NLD]: {
        length: length(8,8), "strictInt": strictInt()
    },
    [Country.BEL]: {
        length: length(10,10), "strictInt": strictInt()
    },
    [Country.ITA]: {
        length: length(11,11), "strictInt": strictInt()
    },
}

export const organisationTaxId = {
    [Country.DEU]: {
        length: length(10,11), "strictInt": strictInt()
    },
    [Country.GBR]: {
        length: length(10,10), "strictInt": strictInt()
    },
    [Country.USA]: {
        length: length(9,9), "strictInt": strictInt()
    },
    [Country.NLD]: {
        length: length(9,9), "strictInt": strictInt()
    },
    [Country.BEL]: {
        length: length(10,10), "strictInt": strictInt()
    }
}

export const organisationVatNumber = {
    [Country.FRA]: {
        regex: regex("^FR\\d{11}$", "start with FR followed by 11 digits")
    },
    [Country.DEU]: {
        regex: regex("^DE\\d{9}$", "start with DE followed by 9 digits")
    },
    [Country.GBR]: {
        regex: regex("^GB\\d{9,12}$", "start with GB followed by 9-12 digits")
    },
    [Country.ESP]: {
        regex: regex("^ES[a-zA-Z0-9]{9}$", "start with ES followed by 9 alphanumeric characters")
    },
    [Country.NLD]: {
        regex: regex("^NL\\d{9}B\\d{2}$", "start with NL followed by 9 alphanumeric characters and B followed by 2 digits")
    },
    [Country.ITA]: {
        regex: regex("^IT\\d{11}$", "start with IT followed by 11 digits")
    },
    [Country.BEL]: {
        regex: regex("^BE\\d{10}$", "start with BE followed by 10 digits")
    },
}

export const soleProprietorRegistrationNumber = {
    [Country.FRA]: {
        length: length(14,14), "strictInt": strictInt()
    },
    [Country.DEU]: {
        format: format("^[A-Za-z]{2,3}\\d{1,6}[A-Za-z]{0,5}$")
    },
    [Country.GBR]: {
        length: length(8,8), "strictInt": strictInt()
    },
    [Country.NLD]: {
        length: length(8,8), "strictInt": strictInt()
    },
    [Country.BEL]: {
        length: length(10,10), "strictInt": strictInt()
    },
    [Country.ITA]: {
        length: length(11,11), "strictInt": strictInt()
    },
}

export const soleProprietorTaxId = {
    [Country.USA]: {
        length: length(9,9), "strictInt": strictInt()
    }
}

export const soleProprietorVatNumber = {
    [Country.GBR]: {
        regex: regex("^GB\\d{9,12}$", "start with GB followed by 9-12 digits")
    },
    [Country.ITA]: {
        regex: regex("^IT\\d{11}$", "start with IT followed by 11 digits")
    },
}

export const accountNumber = {
    [Country.GBR]: {
        length: length(8,8), "strictInt": strictInt(), presence: presence()
    },
    [Country.USA]: {
        length: length(3,17), "strictInt": strictInt(), presence: presence()
    }
}

export const bankCode = {
    [Country.USA]: {
        length: length(9,10), "strictInt": strictInt(), presence: presence()
    }
}

export const iban = {
    [Country.FRA]: {
       // regex: regex("^FR[A-Za-z0-9]{25}$", "start with FR followed by 25 characters")
        presence: presence()
    },
    [Country.DEU]: {
        // regex: regex("^DE[A-Za-z0-9]{20}$", "start with DE followed by 20 characters")
        presence: presence()
    },
    [Country.ESP]: {
        // regex: regex("^ES[A-Za-z0-9]{22}$", "start with ES followed by 22 characters")
        presence: presence()
    },
    [Country.NLD]: {
        // regex: regex("^NL[A-Za-z0-9]{16}$", "start with ES followed by 22 characters")
        presence: presence()
    }
}

export const sortCode = {
    [Country.GBR]: {
        length: length(6,6), "strictInt": strictInt(), presence: presence()
    }
}

export const accountType = {
    [Country.USA]: {
        presence: presence()
    }
}
