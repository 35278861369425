export const BankTransferType = {
    ACH_DEBIT: "ACH_DEBIT",
    BACS_DEBIT: "BACS_DEBIT",
    SEPA_DEBIT: "SEPA_DEBIT"
}

export const Country = {
    USA: "USA",
    GBR: "GBR",
    DEU: "DEU",
    FRA: "FRA",
    ESP: "ESP",
    NLD: "NLD",
    BEL: "BEL",
    ITA: "ITA",
}

export const AppDefaults = {
    country : Country.USA,
    countryCode: "+1",
    maxNumberOfBeneficiaries: 4,
    paymentInstrumentType: "bank_account",
    bankAccountTransfer: BankTransferType.ACH_DEBIT
}

export const Tabs = {
    businessProfile: "businessProfile",
    operations: "operations",
    settlements: "settlements"
}
export const TabOrder = [Tabs.businessProfile, Tabs.operations, Tabs.settlements]

export const CallbackParams = {
    draftMerchantId: "draft_merchant_id",
    merchantId: "merchant_id"
}

export const UrlParams = {
    applicationId: "application_id"
}

export const FormEvents = {
    ENTER: 'enter'
}
export const LocalStorageKeys = {
    DISMISSIBLE_IDS: 'cb_payments_notifications_dismiss_ids',
    READ_IDS: 'cb_payments_notifications_read_ids',
}

export const Api = {
    uploadApi: (merchantId) => `/merchants/${merchantId}/files`
}

export const HttpHeaders = {
    clientName: 'x-cbpayments-client',
    values: {
        adminPortal: 'admin-portal'
    },
}
export const PendoPrefixes = {
    CBpay : 'cbp-',
    CBpayHeader : 'cbp-header-',
    CBpayFooter : 'cbp-footer-',
    CBpayNavbar : 'cbp-nav_menu_item-',
}

export const CbStorage = {
    JwtToken: "cb_payments_jwt_token"
}

export const TabForAddFunds = {
    SELECT_BANK_ACCOUNT: "SELECT_BANK_ACCOUNT",
    ENTER_AMOUNT: "ENTER_AMOUNT",
    REVIEW_AND_CONFIRM: "REVIEW_AND_CONFIRM"
}

export const TabOrderForAddFunds = [TabForAddFunds.SELECT_BANK_ACCOUNT, TabForAddFunds.ENTER_AMOUNT,
    TabForAddFunds.REVIEW_AND_CONFIRM];

export const ExcelDataType = {
    TEXT: {key: "TEXT", defaultValue: "-", dataFormat: null},
    NUMBER: {key: "NUMBER", defaultValue: 0.0, dataFormat: '#,##0'},
    CURRENCY: {key: "CURRENCY", defaultValue: 0.0, dataFormat: '_("$"* #,##0.00_);_("$"* \\(#,##0.00\\);_("$"* "-"??_);_(@_)'},
    DATE: {key: "DATE", defaultValue: "-", dataFormat: "MM/dd/yyyy"},
    BOOLEAN: {key: "BOOLEAN", defaultValue: "-", dataFormat: null}
}

export const reportDateRegExpr = /^\d{2}\/\d{2}\/\d{4}$/gm;

export const DateFilters = {
    TODAY : 'today',
    ONE_WEEK : 'one_week',
    ONE_MONTH : 'one_month',
    THREE_MONTH : 'three_month',
    ONE_YEAR : 'one_year',
    PREVIOUS_MONTH : 'previous_month',
    MONTH_TO_DATE : 'month_to_date',
    QUARTER_TO_DATE : 'quarter_to_date',
    YEAR_TO_DATE : 'year_to_date',
    CUSTOM: 'custom'
}

export const TermsAndConditions = {
    FINIX: "FinixTermsAndConditions",
    ADYEN: "AdyenTermsAndConditions"
}

export const OnboardingStatus = {
    DRAFT: "DRAFT",
    PROVISIONING: "PROVISIONING",
    REJECTED: "REJECTED",
    REJECTED_WITH_DECLINE: "REJECTED_WITH_DECLINE",
    REJECTED_BUT_FIXED: "REJECTED_BUT_FIXED",
    APPROVED: "APPROVED",
}

export const ErrorCodeStatus = {
    NOT_FIXED: "NOT_FIXED",
    FIXED: "FIXED"
}

export const Locales = {
    US: "en-US",
    EU: "en-DE"
}

